window.addEventListener('DOMContentLoaded', function () {
  // Header and Popups
  let $html = document.querySelector('html');
  let $header = document.getElementById('header');
  let $searchGlobal = document.getElementById('global-search');
  let $popupBg = document.querySelector('.popup-background');
  let $searchActive = document.querySelector('.search-active');
  let headerNavigation = document.querySelector('.header__navigation');
  let $hamburger = document.querySelector('.hamburger');
  let $hamburgerMobile = document.querySelector('.hamburger__mobile');
  let $mobile = document.querySelector('.mobile');
  let $hamburgerMenu = document.querySelector('.menu__dropdown');
  let $menuDropdownMobile = document.querySelectorAll('.menu__dropdown__mobile__title');
  let $selectButton = document.querySelector('.select');
  let $selectMenu = document.querySelector('.select__list');
  let $searchMobile = document.getElementById('search__mobile__input');
  let $searchMobileResults = document.getElementById('search__mobile__results');

  let $sliderWrapper = document.querySelector('.swiper-container');
  let linksFilter = document.querySelectorAll('.subtopics__filter__link');

  // Active Link Filter
  for (let i = 0; i < linksFilter.length; i++) {
    linksFilter[i].addEventListener('click', function () {
      for (let j = 0; j < linksFilter.length; j++) {
        linksFilter[j].classList.remove('active');
      }

      this.classList.add('active');
    });
  }

  // Animation Popup
  function addClassAnimationMenu(selectors) {
    let arr = [];
    let flag = false;

    arr.push(
      $hamburger.classList.contains('active'),
      $selectButton.classList.contains('active'),
      $searchGlobal.classList.contains('active'),
      $searchActive.classList.contains('active')
    );

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === true) {
        flag = arr[i];
      }
    }

    if (flag) {
      document.documentElement.classList.add('block-scroll');

      $popupBg.classList.add('active');
      $hamburger.classList.remove('active');
      $hamburgerMenu.classList.remove('active');
      $selectButton.classList.remove('active');
      $selectMenu.classList.remove('active');
      $searchGlobal.classList.remove('active');
      $searchActive.classList.remove('active');
      headerNavigation.classList.remove('active');

      selectors.header.classList.add('dark');
      selectors.elClick.classList.add('active');
      selectors.elMenu.classList.add('active');
    } else {
      document.documentElement.classList.remove('block-scroll');

      selectors.header.classList.remove('dark');
      $popupBg.classList.remove('active');
    }

    if ($searchActive.classList.contains('active')) {
      headerNavigation.classList.add('disable');
      $searchGlobal.style.display = 'none';
    } else {
      headerNavigation.classList.remove('disable');
      $searchGlobal.style.display = 'flex';
    }
  }

  // When click on background, hide all popup's
  $popupBg.addEventListener('click', function () {
    let allClassActive = $header.querySelectorAll('.active:not(svg.active)');

    for (let i = 0; i < allClassActive.length; i++) {
      allClassActive[i].classList.remove('active');
    }

    headerNavigation.classList.remove('disable');
    $searchGlobal.style.display = 'flex';
    $header.classList.remove('dark');
    document.documentElement.classList.remove('block-scroll');
  });

  // Menu dropdown
  $hamburger.addEventListener('click', function () {
    $hamburger.classList.toggle('active');
    $hamburgerMenu.classList.toggle('active');
    addClassAnimationMenu({ elClick: $hamburger, elMenu: $hamburgerMenu, header: $header });
  });

  // Menu dropdown mobile
  $hamburgerMobile.addEventListener('click', function () {
    $hamburgerMobile.classList.toggle('active');
    $mobile.classList.toggle('active');
    $html.classList.toggle('block-scroll');
  });

  for (let i = 0; i < $menuDropdownMobile.length; i++) {
    $menuDropdownMobile[i].addEventListener('click', function (e) {
      e.stopPropagation();
      $menuDropdownMobile[i].classList.toggle('active');
    });
  }

  // Select Menu Topics
  $selectButton.addEventListener('click', function () {
    $selectButton.classList.toggle('active');
    $selectMenu.classList.toggle('active');
    addClassAnimationMenu({ elClick: $selectButton, elMenu: $selectMenu, header: $header });
  });

  // Search
  $searchGlobal.addEventListener('click', function () {
    $searchActive.classList.add('active');
    addClassAnimationMenu({ elClick: $searchGlobal, elMenu: $searchActive, header: $header });
  });

  $searchMobile.addEventListener('click', function () {
    $searchMobileResults.classList.add('active');

    window.addEventListener('click', function (e) {
      if (!e.target.closest('.search__wrap') && !e.target.closest('#search__mobile__results')) {
        $searchMobileResults.classList.remove('active');
      }
    });
  });

  // Scroll to top (button)
  function scrollToTopBtn() {
    let btnScrollToTop = document.getElementById('progress');
    let pos = document.documentElement.scrollTop;

    if (pos > 200) {
      btnScrollToTop.style.display = 'flex';
    } else {
      btnScrollToTop.style.display = 'none';
    }

    btnScrollToTop.addEventListener('click', function () {
      document.documentElement.scrollTop = 0;
    });
  }

  window.onscroll = scrollToTopBtn;

  // Lazy Load
  let lazyloadImages = document.querySelectorAll('img.lazy');
  let lazyloadThrottleTimeout;

  function lazyload() {
    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      let scrollTop = window.pageYOffset;

      for (let i = 0; i < lazyloadImages.length; i++) {
        if (lazyloadImages[i].offsetTop < window.innerHeight + scrollTop) {
          lazyloadImages[i].src = lazyloadImages[i].dataset.src;
          lazyloadImages[i].classList.remove('lazy');
        }
      }

      if (lazyloadImages.length == 0) {
        document.removeEventListener('scroll', lazyload);
        window.removeEventListener('resize', lazyload);
        window.removeEventListener('orientationChange', lazyload);
      }
    }, 20);
  }

  document.addEventListener('scroll', lazyload);
  window.addEventListener('resize', lazyload);
  window.addEventListener('orientationChange', lazyload);

  // Modals
  let html = document.querySelector('html');
  let modalLinks = document.querySelectorAll('*[data-modal-link]');

  for (let i = 0; i < modalLinks.length; i++) {
    modalLinks[i].addEventListener('click', function () {
      let name = this.getAttribute('data-modal-link');
      let modal = document.querySelector('[data-modal-window=' + name + ']');
      let closeModal = modal.querySelector('.modal__close');

      modal.classList.add('open');
      html.style.overflow = 'hidden';

      closeModal.addEventListener('click', function () {
        modal.classList.remove('open');
        html.style.overflow = '';
      });

      window.addEventListener('click', function (e) {
        if (e.target === modal.querySelector('.modal__body')) {
          modal.classList.remove('open');
          html.style.overflow = '';
        }
      });
    });
  }

  // Slider
  new Swiper($sliderWrapper, {
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    simulateTouch: true,
    slidesPerView: 1,
    breakpoints: {
      slidesPerView: 1,
      320: {
        slidesPerView: 1,
      },
    },
  });

  new Swiper('.controll', {
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    simulateTouch: true,
    loop: true,
    breakpoints: {
      slidesPerView: 1,
      320: {
        slidesPerView: 2,
      },
      767: {
        slidesPerView: 3,
      },
      1281: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 5,
      },
    },
  });

  new Swiper('.reviews__swiper__container', {
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    simulateTouch: true,
    centeredSlides: 3,
    spaceBetween: 40,
    loop: true,
    breakpoints: {
      1500: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 4,
      },
      767: {
        centeredSlides: 0,
        slidesPerView: 2,
        pagination: {
          type: 'custom',
        },
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: 1,
        pagination: {
          type: 'fraction',
        },
      },
    },
  });

  new Swiper('.cooperation-slider', {
    simulateTouch: true,
    spaceBetween: 16,
    loop: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      500: {
        slidesPerView: 3,
      },
    },
  });
});

// Maps
google.maps.event.addDomListener(window, 'load', init);

function init() {
  let mapOptions = {
    zoom: 15,
    center: new google.maps.LatLng(37.9172672, -122.3081158, 15.31),
    styles: [
      { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e9e9e9' }, { lightness: 17 }] },
      { featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 20 }] },
      { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }, { lightness: 17 }] },
      { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }] },
      { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 18 }] },
      { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 16 }] },
      { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 21 }] },
      { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#dedede' }, { lightness: 21 }] },
      { elementType: 'labels.text.stroke', stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }] },
      { elementType: 'labels.text.fill', stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }] },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#f2f2f2' }, { lightness: 19 }] },
      { featureType: 'administrative', elementType: 'geometry.fill', stylers: [{ color: '#fefefe' }, { lightness: 20 }] },
      { featureType: 'administrative', elementType: 'geometry.stroke', stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }] },
    ],
  };

  let mapElement = document.getElementById('map');

  let map = new google.maps.Map(mapElement, mapOptions);

  let marker = new google.maps.Marker({
    position: new google.maps.LatLng(40.67, -73.94),
    map: map,
    title: 'Snazzy!',
  });
}
